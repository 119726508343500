/* src/styles/Homepage.css */

.premium-pick-card {
    position: relative;
  }
  
  .date-time-badge {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    background-color: #f0f0f0;
    border-bottom-right-radius: 10px;
  }
  
  .card-content {
    padding-top: 20px;
  }

  .date-time-badge {
    margin-bottom: 10px; /* Adjust as necessary */
}

  
  @media (max-width: 768px) {
    .team-logos img {
      width: 40px;
    }
  }
